import { render, staticRenderFns } from "./SuppliersContent.vue?vue&type=template&id=06bfcf10&scoped=true&"
import script from "./SuppliersContent.vue?vue&type=script&lang=js&"
export * from "./SuppliersContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06bfcf10",
  null
  
)

export default component.exports