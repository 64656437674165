<template>
    <div class="pt-3 mb-3">
        <div role="tablist">
            <!-- item all -->
            <b-card class="accordion-card" no-body>
                <b-card-body style="padding: 0;">
                    <b-list-group>
                        <b-list-group-item
                            :variant="sub_a_variant"
                            class="d-flex justify-content-between align-items-center"
                            style="padding: 0.50rem 1.25rem"
                            to="/suppliers/null"
                        >
                            {{$t('public.supplier.all')}}
                            <b-badge pill variant="primary">{{allCount}}</b-badge>
                        </b-list-group-item>
                    </b-list-group>
                </b-card-body>
            </b-card>
            <!-- Items -->
            <div v-for="item in getGroups">
                <b-card class="accordion-card" no-body>
                    <b-card-header class="accordion-header" role="tab">
                        <b-button v-b-toggle="'accordion-supplier-'+ item.id" :variant="topVariant"
                                  block
                                  class="d-flex justify-content-between align-items-center"
                                  :to="'/suppliers/' + item.id"
                        >
                            <h5 class="mb-0">{{item.title}}</h5>
                            <b-badge pill variant="success">{{item.count}}</b-badge>
                        </b-button>
                    </b-card-header>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AccordionSupplier",
    components: {},
    props: {
        topVariant: {
            type: String,
            default() {
                return 'secondary'
            }
        },
        sub_a_variant: {
            type: String,
            default() {
                return 'primary'
            }
        }
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            uri: {
                uri_public: `api/v1/_public/accordion_supplier`,
            },
            records: null,
            allCount: null,
        }
    },
    mounted() {
        this.baseSelect();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- api calls -->
        baseSelect: function () {
            this.showOverlay(true);
            this.axios.get(this.uri.uri_public)
                .then((response) => {
                    this.$set(this, 'records', response.data.records);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
    },
    computed: {
        getGroups() {
            if (this.__isNull(this.records)) {
                return [];
            }
            // <--  -->
            let records = this.records;
            // <--  -->
            let array = [];
            let record, group, id, name, count;
            let localeTemp;
            let exists = false;
            let allCount = 0;

            Object.keys(records).find((key) => {
                record = records[key];
                id = record.org_group_id;
                count = record.organisations_count;
                allCount += count;
                group = record.org_group;
                // <-- check if group already exists -->
                exists = false;
                array.find((n, index) => {
                    if (n.id === id) {
                        n.count += count;
                        exists = true;
                    }
                });
                // <-- push -->
                if (!exists) {
                    name = group.meta.name;
                    localeTemp = this.__getJsonLocaleValue(group.locale, 'name')
                    name = localeTemp === null ? name : localeTemp;
                    array.push(
                        {
                            id: id,
                            title: name,
                            count: count,
                            order: group.order,
                        }
                    );
                }
            });
            // <-- sort -->
            // array = array.sort((a, b) => b.order - a.order);
            // <--  -->
            this.allCount = allCount;
            return array;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
