<template>
    <div>
        <!-- accordion -->
        <b-row no-gutters>
            <b-col xs="12" sm="12" md="3" lg="2">
                <accordion-supplier></accordion-supplier>
            </b-col>
            <!-- router view -->
            <b-col xs="12" sm="12" md="9" lg="10">
                <router-view></router-view>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import SiderMenuAccordion_deprecated from "@/components/general/elements/menus/SiderMenuAccordion_deprecated";
import AccordionSupplier from "@/components/_public/supplier/AccordionSupplier";
export default {
name: "Suppliers",
        components: {AccordionSupplier, SiderMenuAccordion_deprecated},
        props: {},
        data() {
            return {}
        },
        mounted() {},
        methods: {},
        computed: {},
        filters: {},
        watch: {}
}
</script>

<style scoped>

</style>
